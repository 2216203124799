import { useIntercom } from 'react-use-intercom'
import React, { useEffect } from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch } from 'react-redux'

import LoggedOutLayout from '../components/layouts/LoggedOutLayout'
import { searchQueryParams } from '../lib/helpers'
import { slackMessageAction } from '../hasura/slices/users'

export default function Referral() {
  const dispatch = useDispatch()
  const { trackEvent } = useIntercom()

  const { loginWithRedirect } = useAuth0()

  const source = searchQueryParams('s')

  const goToSignUp = () => {
    trackEvent('clicked-accept-referral')
    loginWithRedirect({ screen_hint: 'signup' })
  }

  useEffect(() => {
    if (!source) return

    trackEvent('opened-referral-link')
    dispatch(slackMessageAction('users', `Lead opened referral link from ${source}.`))
  }, [source])

  return (
    <LoggedOutLayout>
      <Modal centered isOpen size="lg">
        <ModalBody className="p-5 text-center position-relative">
          <h2 className="bold text-primary mb-0">You've been invited!</h2>

          <div className="my-4 mx-auto image-cropper">
            <img
              src={`https://hospital-logos.s3.us-east-2.amazonaws.com/${source}.png`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = 'https://hospital-logos.s3.us-east-2.amazonaws.com/default.png'
              }}
            />
          </div>

          <div className="text-l mb-5">
            <p className="m-0">
              Your friends at <span className="text-capitalize">{source?.replace(/-/g, ' ')}</span> gave you <b>3 FREE</b> Radimal
              radiology reports.
            </p>

            <p className="my-3">
              Radimal is an easy-to-use teleradiology platform that gives you access to specialists, AI predictions, and medical care
              dashboards.
            </p>

            <p className="m-0">Be sure to say thanks!</p>
          </div>

          <Button onClick={goToSignUp} size="lg" color="primary">
            Accept Invitation
          </Button>
        </ModalBody>
      </Modal>
    </LoggedOutLayout>
  )
}
